@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Manrope:wght@200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

/* common-class-start */

*{
  font-family: Poppins;
  margin: 0px;
  box-sizing: border-box;
 }
 a{
  text-decoration: none;
  color: #000 !important;
 }
 .container {
  margin: 0 auto;
  max-width: 1280px;
  width: 90%;
}
ul{
  list-style-type: none;
}

.banner-left{
  float: left;
}
a.exciting-content-button:hover {
  background: #DB0780;
  color: white !important;
}
.white-button:hover{
  background-color: #FFF;
  color: #DB0780 !important;
  box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.08);
}
.link-button a:hover{
  background-color: #FFF;
  color: #DB0780 !important;
  box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.08);
}


 



/* common-class-end */



/* header-css-start */

.container img {
  display: block;
  width: 100%;
}
nav {
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  height: 80px;
  padding: 0 5%;
  background: #FCFCFC;
  box-shadow: 0px 4.8px 6px -3.6px rgba(0, 0, 0, 0.03);
}
nav .logo { 
  float: left;
  width: 45%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #fff;
}
nav .links {
  float: right;
  padding: 0;
  margin: 0;
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
}
nav .links li {
  list-style: none;
}
nav .link-name  {
  display: block;
  padding: 1em;
  font-size: 16px;
  font-family: Lato;
  font-weight: 700;
  color: #000;
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
}
nav .links a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}
nav .links a:hover::before {
  visibility: visible;
  transform: scaleX(1);
  color: white;
}
#nav-toggle {
   position: absolute;
   top: -100px;
}
nav .icon-burger {
  display: none;
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
}
nav .icon-burger .line {
  width: 30px;
  height: 5px;
  background-color: #DB0780;
  margin: 5px;
  border-radius: 3px;
  transition: all .5s ease-in-out;
}
img.header-logo-img {
  width: 18%;
}
.link-button a {
  border-radius: 65px;
  background: #DB0780;
  padding: 14px 34px;
  color: #FFF !important;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
}
.links select#cars {
  border: 0px !important;
  color: #000;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  background-color: #FCFCFC !important;
  -webkit-appearance: none;
}
li.link-button {
  margin-left: 16px;
}
.select-wrapper {
  position: relative;
}
.select-wrapper::after {
  content: "";
  position: absolute;
  font-size: 1rem;
  background-image:url("../src/image/dropdown-icon.png");
  background-repeat: no-repeat;
  top: 7px;
  width: 17%;
  height: 40%;
  right: -13px;
}
.react-multi-carousel-track {
  gap: 30px;
}
option.select-name {
  color: #28282899;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
}
.react-multiple-carousel__arrow {
  z-index: 1 !important;
}
/* header-css-end */





/* footer-css-start */

.footer-upper-left{
  width: 60%;
 }
 .footer-upper-right {
  width: 40%;
  display: flex;
  align-items: center;
  gap: 36px;
  justify-content: end;
}
 img.footer-logo-img {
  width: 100%;
}
.footer-upper-left-inner {
  display: flex;
  align-items: center;
}
ul.footer-link-inner {
  display: flex;
  gap: 26px;
}
.Footer-sec {
  background: #000;
  padding: 25px 0px;
}
a.link-name {
  color: #FFF !important;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}
.Footer-uper {
  border-bottom: 1px solid #E4E4E4;
  display: flex;
  gap: 55px;
  padding-bottom: 22px;
}
.copyright-desc {
  color: #949494;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.start-desc {
  color: #FFF;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
}
.top-desc {
  color: #FFF;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  white-space: nowrap;
}
.Footer-lower {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.white-button {
  border-radius: 65px;
  background: #DB0780;
  padding: 16px 24px;
  color: #FFF !important;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
}
.back-top {
  padding-top: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
}

/* footer-css-end */




/* banner-sec-start */

.banner-sec {
  padding-top: 8%;
  width: 95%;
  margin-left: auto;
  overflow: hidden;
  position: relative;
}
.banner-left{
  margin-top: 9%;
}
.banner-left:before{
  position: absolute;
  content: "";
  width: 34%;
  height: 36%;
  bottom: -54px;
  background-image: url("./image/before-pattern-banner.png");
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 999;
}

.small-heading {
  color: #DB0780;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
}
.banner-discover-desc {
  color: #282828;
  font-family: Manrope;
  font-size: 49px;
  font-weight: 700;
  line-height: 1.2;
}
.banner-heading {
  color: #F930A3;
  font-family: Manrope;
  font-size: 90px;
  font-weight: 800;
  line-height: 1;
  position: relative;
  z-index: 9;
  margin-right: 80px;
}
.banner-title {
  color: #7E7E7E;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  padding-bottom: 45px;
  padding-top: 20px;
}
span.ent-dsec {
  color: #FFF;
  font-family: Manrope;
  font-size: 90px;
  font-weight: 800;
  line-height: 1;
}
.banner-sec:before {
  position: absolute;
  content: "";
  background: url("../src/image/banner-bg-img.png") no-repeat;
  width: 66%;
  height: 100%;
  right: 0;
  top: 82px;
  z-index: 0;
  background-size: 100%;
}
/* carasol-css */


.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding-bottom: 13%;
  padding-top: 5%;
}
.react-multiple-carousel__arrow--left {
  left: calc(73% + 1px) !important;
  bottom: 11%;
}
.react-multiple-carousel__arrow--right {
  right: calc(12% + 1px) !important;
  bottom: 11%;
}
li.react-multi-carousel-item.react-multi-carousel-item--active {
  border-radius: 10px;
  box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.49);
}
/* banner-sec-end */


/* entertainment-package-start */

.package-desc {
  text-align: center;
  padding-bottom: 7%;
}
.package-heading {
  color: #282828;
  font-family: Manrope;
  font-size: 52px;
  font-weight: 700;
  line-height: 50px;
}
span.entertainment {
  color: #DB0780;
}
.package-title {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #7e7e7e;
    padding-top: 16px;
}
.entertainment-package-sec {
  padding-top: 6%;
  padding-bottom: 5%;
} 
.package-categiory {
  display: flex;
  justify-content: center;
  gap: 50px;
  padding-bottom: 4%;
}
.package-shape {
  height: 240px;
  width: 247px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0px 50%  50% 50%;
}
.movie{
  background-image: url("../src/image/movie-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.Audiobooks {
  background-image: url("../src/image/autoplay-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.Concerts{
  background-image: url("../src/image/concert-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.Games{
  background-image: url("../src/image/games-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.Sport{
  background-image: url("../src/image/sports-eposide-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.Premium-Software{
  background-image: url("../src/image/permium-software-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.Radio-Channels{
  background-image: url("../src/image/radio-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.over {
  color: #FFF;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
}

.package-number {
  color: #FFF;
  font-family: Manrope;
  font-size: 52px;
  font-weight: 700;
  line-height: 1.2;
}
.package-name {
  color: #FFF;
  font-family: Manrope;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.2;
}




/* entertainment-package-end */



/* exciting-content-start */

.exciting-content-sec {
  background-image: url("../src/image/exciting-content-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
    background-attachment: fixed;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    font-size: 24px;
}
.exciting-content-inner {
  text-align: center;
  padding: 4% 0%;
}
.exciting-content-heading {
  color: #FFF;
  font-family: Manrope;
  font-size: 54px;
  line-height: 1.6;
}
.exciting-content-title {
  color: #FFF;
  font-family: Poppins;
  font-size: 19px;
  font-weight: 400;
  line-height: 1.3;
  padding-bottom: 4%;
}
span.price-bold {
  color: #FFF;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.3;
}
a.exciting-content-button {
  border-radius: 65px;
  background: #FFF;
  box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.08);
  color: #DB0780 !important;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  padding: 12px 22px;
}

/* exciting-content-end */


/* software-and-app-start */

.software-app-sec {
  padding-top: 4%;
  padding-bottom: 2%;
}
.software-app-desc {
  text-align: center;
}
.software-app-heading {
  color: #282828;
  font-family: Manrope;
  font-size: 48px;
  font-weight: 800;
  line-height: 1.3;
}
span.software-app-heading-bold {
  color: #DB0780;
  font-family: Manrope;
  font-size: 52px;
  font-weight: 800;
  line-height: 1.3;
}
.software-app-title {
  color: #7e7e7edb;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  padding-top: 1%;
}
.software-app-card-title {
  color: #282828ba;
  font-family: Manrope;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  padding-top: 16px;
  padding-left: 16px;
}
.software-app-card-heading {
  border-radius: 10px;
  border: 14px solid rgba(219, 7, 128, 0.05);
  color: #282828;
  font-family: Manrope;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  padding: 18px 47px;
  position: relative;
}
.software-app-card {
  display: flex;
  justify-content: space-between;
  padding-top: 5%;
}
.software-app-card-inner {
  width: calc( 100% / 4 - 28px);
  position: relative;
}

.software-app-card-inner:nth-child(1) .software-app-card-heading:before {
  content: "01";
  position: absolute;
  top: -42%;
  left: -15%;
  font-size: 32px;
  color: #F7D50E;
  font-weight: 800;
}
.software-app-card-inner:nth-child(2) .software-app-card-heading:before {
  content: "02";
  position: absolute;
  top: -42%;
  left: -15%;
  font-size: 32px;
  color: #F7D50E;
  font-weight: 800;
}
.software-app-card-inner:nth-child(3) .software-app-card-heading:before {
  content: "03";
  position: absolute;
  top: -42%;
  left: -15%;
  font-size: 32px;
  color: #F7D50E;
  font-weight: 800;
}

.software-app-card-inner:nth-child(4) .software-app-card-heading:before {
  content: "04";
  position: absolute;
  top: -42%;
  left: -15%;
  font-size: 32px;
  color: #F7D50E;
  font-weight: 800;
}
.software-app-card-inner:nth-child(1) img.update-img {
  position: absolute;
  width: 75px;
  top: 10%;
  left: -14%;
}
.software-app-card-inner:nth-child(2) img.update-img {
  position: absolute;
  width: 75px;
  top: 10%;
  left: -14%;
}
.software-app-card-inner:nth-child(3) img.update-img {
  position: absolute;
  width: 75px;
  top: 10%;
  left: -14%;
}
.software-app-card-inner:nth-child(4) img.update-img {
  position: absolute;
  width: 75px;
  top: 10%;
  left: -14%;
}
.software-app-button {
  text-align: center;
  padding-top: 8%;
}

/* software-and-app-end */



/* explored-sec-start */


.explored-inner {
  text-align: center;
}
h3.explored-heading {
  color: #282828;
  font-family: Manrope;
  font-size: 52px;
  font-weight: 800;
  line-height: 1.2;
}
span.explored-bold {
  color: #DB0780;
  font-family: Manrope;
  font-size: 52px;
  font-weight: 800;
  line-height: 1.2;
}
.explored-sec {
  background: #F8F8F8;
  margin-top: 4%;
  padding: 4% 0% 6% 5%;
}
.explored-title {
  color: #7E7E7E;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  padding-top: 10px;
}
.explored-images {
  display: flex;
  padding-top: 7%;
  position: relative;
  justify-content: space-between;
}
img.explored-img {
  width: 380px;
}
.before-img::before{
  content: "";
  width: 17%;
  height: 40%;
  background-image: url("../src/image/explore-before.png");
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  right: -31px;
  top: 57px;
  z-index: 99;

}
.before-img1::before{
  content: "";
  width: 17%;
  height: 30%;
  background-image: url("../src/image/explore-before.png");
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  left: -64px;
  top: 39px;
  z-index: 1;

}

.before-img2::before{
  content: "";
  width: 20%;
  height: 51%;
  background-image: url("../src/image/explore-before.png");
  background-repeat: no-repeat;
  background-size: 93%;
  position: absolute;
  left: 48%;
  bottom: -40px;
  z-index: 1;

}
.explored-button {
  text-align: center;
  padding-top: 9%;
}


/* explored-sec-end */



/* enjoy-sec-start */

.enjoy-sec {
  padding-top: 6%;
  padding-bottom: 6%;
}

.enjoy-inner {
  text-align: center;
}
.enjoy-images {
  display: flex;
  justify-content: center;
  gap: 12%;
  padding-top: 6%;
}
.image-heading {
  color: #282828;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  padding-top: 6%;
}

/* enjoy-sec-end */



/* any-device-start */

.any-device-sec{
  background-image: url("../src/image/any-device-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
 }

.any-device-sec {
  padding-top: 4%;
  padding-bottom: 4%;
}
.any-device-inner {
  text-align: center;
}

.any-device-heading {
  color: #FFF !important;
  padding-bottom: 4%;
}

.device-img {
  padding-top: 7%;
}
.tabset > input[type="radio"] {
  position: absolute;
  left: -200vw;
}

.tabset .tab-panel {
  display: none;
}

.tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
.tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
.tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
.tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
.tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
.tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
  display: block;
}


.tabset > label.tab1 {
  position: relative;
  border-radius: 100px;
  background: #FFF !important;
  color: #DB0780 !important;
  font-family: Manrope;
  padding: 19px 32px 20px 70px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin-right: 3%;
}
label.tab1 {
  position: relative;
}
label.tab1 svg {
  position: absolute;
  top: 8px;
  left: 10%;
}
label.tab2 {
  position: relative;
}
label.tab2 svg {
  position: absolute;
  top: 8px;
  left: 11%;
}

.tabset > label.tab2 {
  position: relative;
  border-radius: 100px;
  background: #DB0780 !important;
  color: #FFF !important;
  font-family: Manrope;
  padding: 19px 32px 20px 86px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.tabset > label::after {
  content: "";
  position: absolute;
  left: 15px;
  bottom: 10px;
  width: 22px;
  height: 0px;
  background: #8d8d8d;
}

input:focus-visible + label {
  outline: 2px solid rgba(0,102,204,1);
  border-radius: 3px;
}

.tabset > label:hover,
.tabset > input:focus + label,
.tabset > input:checked + label {
  color: #06c;
}

.tabset > label:hover::after,
.tabset > input:focus + label::after,
.tabset > input:checked + label::after {
  background: #06c;
}

.tabset > input:checked + label {
  margin-bottom: -1px;
  cursor: pointer;
}
label.tab2 {
  cursor: pointer;
}

.tab-panel {
  padding: 56px 0 0;
}
.frames{
  position: relative;
}
.tab-frame img {
  display: block;
  width: 60%;
}

.adhd-top-video .adhd-top-video2 {
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
}
.adhd-top-video video {
  box-sizing: border-box;
  background: url(https://www.adhdcentre.co.uk/wp-content/uploads/2018/02/macbook.png) center center no-repeat;
  background-size: contain;
  padding: 11.9% 15.5% 14.8%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.tab-frame .adhd-top-video2{
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
}
.tab-frame video {
  box-sizing: border-box;
  background: url("./image/tab-frame.png") no-repeat;
  background-size: contain;
  padding: 0.9% 2% 25.8%;
  position: absolute;
  top: 0;
  left: 0;
  width: 61%;
  height: 100%;
}
.frames {
  position: relative;
  overflow: hidden;
}
.phone-frame {
  position: absolute;
  top: 38%;
  right: -60%;
  z-index: 1;
  width: 100%;
}
.phone-frame .adhd-top-video2 {
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
}
.phone-frame video {
  box-sizing: border-box;
  background: url("./image/phone-img.png") no-repeat;
  background-size: contain;
  padding: 0.9% 1% 50.8%;
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  height: 100%;
}
section#rauchbier {
  margin-bottom: -12%;
  margin-top: 5%;
}
/* any-device-end */




/* Faq-sec-start */

.faq-sec {
  padding-bottom: 5%;
}
.faq-inner {
  display: flex;
}
.faq-img {
  width: 50%;
}
.faq-desc {
  width: 50%;
}
img.side-img {
  width: 88%;
}
.accordion {
  margin: 0 auto;
  padding-top: 6%;
}

.accordion-section {
  border-radius: 20px;
  border: 1px solid #db07802e;
  background: rgba(219, 7, 128, 0.02);
  margin-bottom: 15px;
}

.accordion-header {
  padding: 24px 20px;
  border-radius: 6px;
  background: rgba(249, 249, 249, 0.50);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: #282828;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
}
.accordion-body {
  padding: 10px;
  border-top: 1px solid #ddd;
  color: #747474;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 2;
  padding: 10px 23px;
}

/* Styles for the active (open) section */
.accordion-section.active .accordion-body {
  display: block;
}



/* Faq-sec-end */






/* responsive-start */

@media only screen and (min-width:1540px){
  .banner-sec:before {
    width: 74%;
}
}
@media only screen and (max-width:1399px){

  /* banner-sec-start */
  .banner-discover-desc {
    font-size: 42px;
   }
.banner-title {
  font-size: 14px;
}
.banner-heading {
  font-size: 76px;
}
span.ent-dsec {
  font-size: 76px;

}

.banner-sec:before {
  width: 68%;
}

  /* banner-sec-start */
}
@media only screen and (max-width:1299px){

  /* banner-sec-start */

.banner-discover-desc {
    font-size: 38px;
}
.banner-heading {
  font-size: 70px;
}
span.ent-dsec {
  font-size: 70px;
}
.react-multiple-carousel__arrow--left {
  left: calc(70% + 1px) !important;
}
img.header-logo-img {
  width: 12%;
}


/* banner-sec-start */


/* entertainment-package-start */

.package-heading {
  font-size: 45px;
}
.package-title {
  font-size: 14px;
}
.package-shape {
  height: 215px;
  width: 215px;
}

/* entertainment-package-end */


/* software-and-app-start */
.software-app-card-title br {
  display: none;
}
.software-app-card-heading {
  font-size: 17px;
}
.software-app-heading {
  font-size: 45px;
}
span.software-app-heading-bold {
  font-size: 45px;
}
.software-app-title {
  font-size: 14px;
}
/* software-and-app-end */


/* explored-start */

h3.explored-heading {
  font-size: 45px;
}
span.explored-bold {
  font-size: 45px;
}
img.explored-img {
  width: 316px;
}
.explored-title {
  font-size: 14px;
  padding-top: 14px;
}
/* explored-end */

/* footer-sec */

.footer-upper-right {
  gap: 18px;
}

}
@media only screen and (max-width:1199px){

  .banner-discover-desc {
  font-size: 30px; 
  }
  .banner-heading {
    font-size: 70px;
  }
  span.ent-dsec {
    font-size: 70px;
  }
  .banner-sec:before {
    width: 61%;
}
  .banner-title {
    font-size: 12px;
  }
  .package-shape {
    height: 155px;
    width: 155px;
  }
  .software-app-card-heading {
    font-size: 14px;
    padding: 21px 32px;
  }
  p.software-app-card-title br {
    display: none;
  }
  .software-app-card-title {
    font-size: 12px;
  }
  .explored-title {
    font-size: 12px;
  }
  h3.explored-heading {
    font-size: 36px;
  }
  span.explored-bold {
    font-size: 36px;
}
  img.explored-img {
    width: 250px;
 }
 a.link-name {
 font-size: 12px;
 }
 .start-desc {
  font-size: 14px;
}
.white-button {
  font-size: 14px;
}
.react-multiple-carousel__arrow--left {
  left: calc(60% + 1px) !important;
}
.package-number {
  font-size: 32px;
}
.accordion-header {
  padding: 19px 18px;
}
ul.footer-link-inner {
  gap: 16px;
}
.phone-frame iframe {
  padding: 0.9% 1% 51.8%;
}

   
}
@media only screen and (max-width:991px){

/* header-sec */
  nav .links {
  width: 55%;
 }

 /* banner-sec */

  .banner-heading {
    font-size: 52px;
    }
    span.ent-dsec {
      font-size: 52px;
  }
  .banner-title {
    font-size: 10px;
}
  .banner-discover-desc {
    font-size: 23px;
  }
  .banner-sec {
    padding-top: 16%;
  }
.react-multiple-carousel__arrow--left {
  left: calc(53% + 1px) !important;
}


  /* software-app-sec */
  .software-app-card-inner {
    width: calc(100% / 2 - 28px);
  }
  .software-app-card {
    flex-wrap: wrap;
   }
   .software-app-heading {
    font-size: 45px;
}


   /* entertainment-package */
  .package-title {
    font-size: 12px;
  }
  .package-heading {
    font-size: 45px;
}
  .package-shape {
    height: 128px;
    width: 128px;
 }
 .over {
  font-size: 16px;
 }
 .package-number {
   font-size: 24px;
 }
 .package-name {
   font-size: 12px;
 }
 .exciting-content-heading {
  font-size: 26px;
 }
 .exciting-content-sec {
  background-size: cover;
 }
 span.price-bold {
  font-size: 18px;
  }
  .exciting-content-title {
    font-size: 15px;
  }
  span.software-app-heading-bold {
    font-size: 45px;
}
  h3.explored-heading {
    font-size: 45px;
  }
  span.explored-bold {
    font-size: 45px;
}
 .software-app-card-heading {
  font-size: 28px;
 }
 .software-app-card-inner:nth-child(1) .software-app-card-heading:before {
  top: -31%;
  left: -10%;
 }
 .software-app-card-inner:nth-child(2) .software-app-card-heading:before {
  top: -31%;
  left: -10%;
 }
 .software-app-card-inner:nth-child(3) .software-app-card-heading:before {
  top: -31%;
  left: -10%;
 }
 .software-app-card-inner:nth-child(4) .software-app-card-heading:before {
  top: -31%;
  left: -10%;
 }
 .software-app-card-inner:nth-child(1) img.update-img {
  top: 20%;
  left: -8%;
 }
 .software-app-card-inner:nth-child(2) img.update-img {
  top: 20%;
  left: -8%;
}
.software-app-card-inner:nth-child(3) img.update-img {
  top: 20%;
  left: -8%;
}
.software-app-card-inner:nth-child(4) img.update-img {
  top: 20%;
  left: -8%;
}
.software-app-card-title {
  font-size: 17px;
}
.software-app-card {
  flex-wrap: wrap;
  gap: 40px;
}
.faq-img {
  width: 100%;
}
.faq-inner {  
  flex-direction: column;
}
.faq-desc {
  width: 100%;
  padding-top: 10%;
}
.enjoy-images {
  gap: 5%;
}
.explored-sec .container img {
  width: 88%;
}
.software-app-card {
  margin-left: 15px;
}
.phone-frame video {
  padding: 0.9% 1% 52.8%;
 }

/* footer-sec */

.Footer-uper {
  flex-direction: column;
  gap: 29px;
}
.footer-upper-left {
  width: 100%;
}
.footer-upper-right {
  width: 100%;
  justify-content: start;
}
}
@media only screen and (max-width:767px){

 /* header-start */

  nav .logo {
    float: none;
    width: auto;
    justify-content: start;
}
nav .links {
  float: none;
  position: fixed;
  z-index: 9;
  left: 0;
  right: 0;
  top: 77px;
  bottom: 100%;
  width: auto;
  height: auto;
  z-index: 99999999 !important;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #F8F8F8;
  overflow: hidden;
  transition: all .5s ease-in-out;
}
nav .links a {
    font-size: 20px;
}
nav :checked ~ .links {
    bottom: 0;
}
nav .icon-burger {
    display: block;
}
nav :checked ~ .icon-burger .line:nth-child(1) {
    transform: translateY(10px) rotate(225deg);
}
nav :checked ~ .icon-burger .line:nth-child(3) {
    transform: translateY(-10px) rotate(-225deg);
}
nav :checked ~ .icon-burger .line:nth-child(2) {
    opacity: 0;
}

 /* header-start */

/* banner-sec */

.banner-sec {
  width: 100%;
  margin-left: unset;
}
.banner-left {
  float: unset;
}
.banner-sec:before {
  width: 100%;
  height: 60%;
  right: 0;
  top: 52%;
}
.banner-left {
  margin-bottom: 38px;
  text-align: center;
}
span.ent-dsec {
  color: #F930A3;

}
.banner-heading {
  margin-right: 0px;
}
.banner-left:before {
  width: 100%;
  height: 27%;
  top: 22%;
  right: -5%;
  background-size: 81%;
}
 .react-multi-carousel-track {
  gap: 0px;
}
.react-multi-carousel-list {
  padding-top: 20%;
}
.react-multiple-carousel__arrow--left {
  display: none;
}
.react-multiple-carousel__arrow--right {
  display: none;
}
 .package-categiory {
  flex-wrap: wrap;
}
.entertainment-package-sec .container {
  display: flex;
  flex-direction: column;
}
div#package-order-three {
  order: 3;
}
div#package-order-two {
  order: 2;
}
.exciting-content-sec {
  background-size: cover;
}
.software-app-heading {
  font-size: 32px;
}
.explored-sec .container img {
  width: 84%;
}
span.software-app-heading-bold {
  font-size: 32px;
}
.software-app-title {
  font-size: 13px;
}
.software-app-card-heading {
  font-size: 20px;
}
.software-app-card-title {
  font-size: 13px;
}
span.explored-bold {
  font-size: 32px;
}
h3.explored-heading {
  font-size: 32px;
}
.before-img::before {
  right: -8px;
}
p.enjoy-title.explored-title br {
  display: none;
}
.enjoy-images {
  gap: 0%;
}
.before-img2::before {
  bottom: -15px;
}
.before-img1::before {
  width: 17%;
  height: 30%;
}
.enjoy-sec .container img {
  display: block;
  width: 50%;
  margin: auto;
}
.phone-frame video {
  padding: 0.9% 1% 55.8%;
}
.explored-sec {
  padding: 4% 0% 13% 5%;
}
.enjoy-sec {
  padding-bottom: 9%;
}

/* footer-sec */
.Footer-uper {
  flex-direction: column;
}
.footer-upper-right {
  width: 100%;
}

}
@media only screen and (max-width:575px){
 /* banner-sec */

 
 .banner-sec:before {
   top: 50%;
 }
   .banner-heading {
    font-size: 45px;
  }
  .banner-left:before {
    top: 26%;
    background-size: 89%;
  }

/* entertainment-package-sec */
div#package-order-three {
  order: 2;
}
div#package-order-two {
  order: 3;
  padding-top: 30px;
}

/* exciting-content-sec */

.exciting-content-sec {
  background-image: url(./image/responsive=bg.png);
}
.exciting-content-inner {
  padding: 17% 0%;
}

/* software-app-sec  */
.software-app-card-inner {
  width: calc(100% / 1 - 28px);
}
.exciting-content-title {
  font-size: 12px;
}
.software-app-card {
  margin-left: 38px;
}
.software-app-title {
  padding-top: 5%;
}

/* explored-sec */

span.explored-bold {
  font-size: 26px;
}
h3.explored-heading {
  font-size: 26px;
}
.before-img1 {
  width: 40%;
}
.before-img2 {
  width: 40%;
}
.before-img {
  width: 40%;
  padding-top: 38px;
}
.explored-images {
  justify-content: space-around;
  flex-wrap: wrap;
}
.before-img1::before {
  left: -15px;
}





/* enjoy-sec */

.enjoy-images {
  flex-wrap: wrap;
  justify-content: space-around;
}
.enjoy-music {
  width: 35%;
}
.enjoy-concert {
  width: 35%;
}
.enjoy-autobooks {
  width: 35%;
}


/* any-device-sec */

label.tab1 svg {
  left: 17%;
  width: 28px;
}
label.tab2 svg {
  left: 17%;
  width: 28px;
}
.tabset > label.tab2 {
  font-size: 14px;
}
.any-device-heading {
  padding-bottom: 11%;
}
.tabset > label.tab1 {
  padding: 19px 32px 20px 59px;
}
.tabset > label.tab2 {
  padding: 19px 32px 20px 66px;
}
.any-device-sec {
  background-image: url("./image/tab-bg-responsive.png");
}
.any-device-sec {
  padding-top: 13%;
}


/* faq-sec */
.accordion-header {
  font-size: 13px;
}

/* footer-sec */

.footer-upper-left-inner {
  align-items: start;
  flex-direction: column;
}
.Footer-lower {
  align-items: start;
  flex-direction: column;
  padding-top: 18px;
}
ul.footer-link-inner {
  padding: 0;
}
.footer-logo {
  padding-bottom: 16px;
}
}
@media only screen and (max-width:410px){
  .tabset > label.tab1 {
    font-size: 12px;
  }
  .tabset > label.tab2 {
    font-size: 12px;
}
.tab-frame video {
  padding: 0.9% 2% 30.8%;
}
ul.footer-link-inner {
  gap: 9px;
}
nav .icon-burger {
  right: 10%;
}
.banner-heading {
  font-size: 30px;
} 
span.ent-dsec {
  font-size: 30px;
}
.banner-discover-desc {
  font-size: 16px;
}
.small-heading {
  font-size: 8px;
}
.banner-left:before {
  top: 23%;
  background-size: 80%;
}
.package-heading {
  font-size: 30px;
  line-height: 37px;
}
.banner-sec {
  padding-top: 22%;
}
a.link-name {
  font-size: 11px;
}
.react-multi-carousel-list {
  padding-top: 7%;
  padding-bottom: 22%;
}
.banner-sec:before {
  top: 44%;
}
span.software-app-heading-bold {
  font-size: 30px;
}
.software-app-heading {
  font-size: 30px;
}
.tabset > label.tab2 {
  padding: 18px 32px 20px 63px;
}
.tabset > label.tab1 {
  padding: 18px 32px 20px 53px;
}
label.tab1 svg {
  left: 13%;
  top: 5px;
}
.top-desc {
  font-size: 14px;
}
.exciting-content-heading {
  font-size: 24px;
}
.react-multiple-carousel__arrow--right {
  bottom: 8%;
}
.react-multiple-carousel__arrow--left {
  left: calc(47% + 1px) !important;
  bottom: 8%;
}
.software-app-sec {
  padding-bottom: 10%;
}
.explored-button {
  padding-top: 18%;
}
}


